import { PageProps } from 'gatsby';
import { FC } from 'react';
import { Layout, SEO } from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Terms and Conditions',
  order: 100,
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <SEO path={props.location?.pathname} />
      <div></div>
    </Layout>
  );
};

export default Page;
